label {
    margin-bottom: 0.5rem;
}

.bi {
    vertical-align: -0.125em;
    fill: currentColor;
}

.info-icon {
    margin-left: 5px;
    cursor: pointer;
}
