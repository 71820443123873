#invoice {
    width: 936px;
    min-height: 1400px;
    padding: 20px;
    background-color: white; /* Ensure background is white for the PDF */
    /* width: 210mm;
    min-height: 297mm; */
    font-size: 18px;
    font-family: Arial, sans-serif;
    word-spacing: normal;
    letter-spacing: normal;
}

.invoice h1,
.invoice h2,
.invoice h5,
.invoice p {
    margin: 0;
    padding: 0;
}

.invoice p {
    margin-top: 5px;
}

.invoice .text-end {
    text-align: end;
}

.invoice .font-weight-bold {
    font-weight: bold;
}

.fs-8 {
    font-size: 0.8em;
}
#invoice .edit-textarea {
    margin-left: -13px;
    margin-top: -2px;
    margin-bottom: -7px;
    font-size: 18px;
}
#invoice .edit-input {
    font-size: 18px;
    width: 118px;
    display: unset;
    margin: -7px -11px -1px 0px;
    padding: 0 7px;
}
.red {
    color: red;
}
.title {
    text-transform: uppercase;
}
.bold {
    font-weight: bold;
}
#invoice table td {
    padding: 0;
}
#logo {
    max-width: 280px;
}
#invoice hr {
    margin: 5px 0;
    color: #000;
}
.invoice button {
    width: 300px;
}
.payment-details {
    margin-top: 250px;
}
.payment-details-with-po {
    margin-top: 150px;
}
